export default {
  nl: {
    confirm: "Bevestigen",
    cancel: "Uitstellen",
    title: "Gegevens bevestigen",
    title_company: "Onderneming(en)",
    title_office: "Kanto(o)r(en)",
    descriptionCompanies:
      'Indien u ook de gegevens van uw onderneming(en) wenst te bevestigen, klik dan op de knop van de onderneming om te bevestigen. Indien u ervoor kiest om dit op een later tijdstip te doen, klik dan op "Uitstellen".',
    descriptionPersonal:
      "Conform de wetgeving betreffende het openbaar register dient U éénmaal per jaar uw persoonlijke gegevens in uw e-loket te valideren. Welke gegevens? Professioneel adres, professioneel telefoonnummer en e-mailadres publiek register. U kan dit best onmiddellijk doen door uw gegevens nu te controleren, desgevallend aan te passen en daarna te klikken op “Gegevens bevestigen”. ",
    descriptionPersonalCanIgnore: " U kan dit ook nog op een later tijdstip doen. Indien u uw gegevens niet hebt gevalideerd zal de validatie-pop-up opnieuw verschijnen.",
      descriptionOffices:
      'Indien u ook de gegevens van uw kanto(o)r(en) wenst te bevestigen, klik dan op de knop van het kantoor om te bevestigen. Indien u ervoor kiest om dit op een later tijdstip te doen, klik dan op "Uitstellen".',
    alertMaxPostponements:
      'Opgelet. Dit is de laatste keer dat u het bevestigen van de gegevens kan uitstellen.',
    countDown: "Je kan nog maximaal {{remaining}} keer uitstellen.",
    resetPostponements: "Reset uitstel",
  },
  fr: {
    confirm: "Confirmer",
    cancel: "Reporter",
    title: "Confirmer les données",
    title_company: "Entreprise(s)",
    title_office: "Cabinet(s)",
    descriptionCompanies:
      'Si vous souhaitez également confirmer les coordonnées de votre (vos) entreprise(s), cliquez sur le bouton d\'entreprise. Si vous choisissez de le faire plus tard, cliquez sur "Reporter".',
    descriptionPersonal:
      "Conformément à la législation concernant le registre public, vous devez valider au moins une fois par an, vos données personnelles dans votre e-guichet. Quelles données? Adresse professionnelle, numéro de téléphone professionnel et adresse e-mail registre public. Il est préférable de le faire immédiatement en vérifiant vos données maintenant, en les modifiant si nécessaire, puis en cliquant sur “Confirmer les données”. Vous pouvez également le faire ultérieurement. Si vous n’avez pas validé vos données, la fenêtre de validation réapparaîtra.",
    descriptionOffices:
      'Si vous souhaitez également confirmer les coordonnées de votre (vos) cabinet(s), cliquez sur le bouton du cabinet pour confirmer. Si vous préférez de le faire ultérieurement, cliquez sur "Reporter".',
    alertMaxPostponements:
      'Attention. C´est la dernière fois que vous pouvez reporter la confirmation des données.',
    countDown: "Vous pouvez reporter un maximum de {{remaining}} fois.",
    resetPostponements: "Réinitialiser le report",
  },
};
