export default {
  nl: {
    itaaNumber: "ITAA-nummer",
    detailsConfirmed: "Gegevens bevestigd op",
    contactDetails: "Professionele gegevens",
    select_source_professional_contactdetails:
      "Hoe wenst u het adres waar u kantoor houdt, in te geven?",
    kbo_text:
      "De KBO is de authentieke bron voor het adres van uw ondernemings- of vestigingsnummer dat automatisch aangevuld wordt hieronder. Gelieve eventuele aanpassingen dus in de KBO te doen. Het ITAA kan deze niet voor u doorvoeren.",
    companynumber: "Uw ondernemings- of vestiginseenheidsnummer:",
    street: "Straat + nr",
    country: "Land",
    postalcode: "Postcode",
    city: "Plaats",
    phone_officehours: "Telefoon (kantooruren)",
    text_public_register_phone_web:
      "Bovenstaande gegevens, zijnde het adres en telefoonnummer waar u kantoor houdt, worden getoond in het publiek register. Daarnaast kan u er optioneel voor kiezen om, indien verschillend van bovenstaand telefoonnummer, een bijkomend mobiele telefoon in te geven waarop het ITAA u kan contacteren. Onderaan deze pagina heeft u de mogelijkheid om die al dan niet te tonen in het publiek register. Dit geldt eveneens voor de website van uw kantoor.",
    mobilephone: "Mobiele telefoon",
    web: "Webpagina",
    text_public_register_email:
      "Onderstaand e-mailadres is het e-mailadres dat u gebruikt om zich aan te melden op het ITAA-portaal. Maar wordt ook gebruikt voor de communicatie met het Instituut en de facturatie, en wordt eveneens getoond in het publiek register. Dit is een verplicht gegeven.",
    public_register_email: "E-mailadres publiek register",
    contactDetailsPrivate: "Privé contactgegevens",
    phone: "Telefoon",
    save: "Opslaan",
    text: "Beheer uw privé en professionele gegevens.",
    text_itaadata: "Raadpleeg uw privé en professionele gegevens.",
    error: "Uw ondernemingsnummer werd niet gevonden in de KBO. Gelieve uw gegevens manueel in te vullen.",
    noResult: "Geen resultaat",
    modal_success_text: "De gegevens zijn bijgewerkt.",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen...",
    nationalNumber: "Rijksregisternummer",
    wrong_nationalnumber: "Dit rijksregisternummer kan niet opgeslagen worden.",
  },
  fr: {
    itaaNumber: "Numéro ITAA",
    detailsConfirmed: "Données confirmées le",
    contactDetails: "Coordonnées professionnelles",
    select_source_professional_contactdetails:
      "Comment souhaitez-vous saisir l'adresse où vous avez votre cabinet?",
    kbo_text:
      "La BCE est la source authentique pour l’adresse de votre numéro d’entreprise ou de votre unité d’établissement qui sera automatiquement complétée ci-dessous. Veuillez donc effectuer toute modification dans la BCE. L’ITAA ne peut pas les effectuer pour vous.",
    companynumber:
      "Votre numéro de l'entreprise ou de l'unité d'établissement:",
    street: "Adresse",
    country: "Pays",
    postalcode: "Code postal",
    city: "Localité",
    phone_officehours: "Téléphone (heures de bureau)",
    text_public_register_phone_web:
      "Les informations ci-dessus, étant l'adresse et le numéro de téléphone où vous avez votre cabinet, seront indiquées dans le registre public. De plus, vous pouvez choisir, s'il est différent du numéro de téléphone ci-dessus, d'entrer un téléphone mobile supplémentaire sur lequel l'ITAA pourra vous contacter. En bas de cette page, vous avez la possibilité de les afficher ou non dans le registre public. Ceci s'applique également au site web de votre cabinet.",
    mobilephone: "GSM",
    web: "Page web",
    text_public_register_email:
      "L'adresse e-mail ci-dessous est l'adresse e-mail que vous utilisez pour vous connecter au portail ITAA. Mais elle est également utilisée pour la communication avec l'Institut et la facturation, et elle est également affichée dans le registre public. Ceci est un élément obligatoire.",
    public_register_email: "Adresse e-mail registre public",
    contactDetailsPrivate: "Coordonnées privées",
    phone: "Téléphone",
    save: "Sauvegarder",
    text: "Gérer vos coordonnées privées et professionnelles.",
    text_itaadata: "Consultez vos coordonnées privées et professionnelles.",
    error: "Votre numéro d'entreprise n'a pas été trouvé dans le BCE. Veuillez saisir vos coordonnées manuellement.",
    noResult: "Pas de résultat",
    modal_success_text: "Les données ont été mises à jour.",
    notSaved: "Vos modifications n’ont pas encore été enregistrées...",
    nationalNumber: "Numéro de registre national",
    wrong_nationalnumber: "Ce numéro de registre national ne peut pas être sauvegardé.",
  },
};
