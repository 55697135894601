import axiosClient from "./../../../../axiosClient";

export const getUserImpersonations = (pageNumber, query) => {
  if (query) {
    return axiosClient
      .get(
        `/api/Impersonation?pageNumber=${pageNumber}&search=${query}`
      )
      .then((response) => {
        if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
  } else {
    return axiosClient
      .get(`/api/Impersonation?pageNumber=${pageNumber}`)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
      })
      .catch((error) => {
        throw new Error(`HTTP request failed: ${error.message}`);
      });
  }
};