import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCompanyName } from '../../../../config/formatUtils';
import { getOfficeRoles } from './http/HttpClientHelper';
import NaturalPersonInfoHeader from '../../NaturalPersonInfoHeader/NaturalPersonInfoHeader';
import { EntityType } from '../../../../Enums/EntityType';

const OfficeRoles = ({ userDetail }) => {
    const { t } = useTranslation();
    const [officeRoles, setOfficeRoles] = useState([]);

    useEffect(() => {
        (async () => {
            const roles = await getOfficeRoles(userDetail.id);
            setOfficeRoles(roles);
        })();
    }, [userDetail]);

    return (
        <div id="main">
            <div id="content">
                <NaturalPersonInfoHeader userDetail={userDetail} />
                <div className="text-h1">
                    {t('office_roles.myOfficeRoles')}
                </div>
                <div className='form-group'>
                    <div className='form-row'>
                        <p dangerouslySetInnerHTML={{ __html: t('groups.text') }} />
                    </div>
                    {officeRoles.length > 0 ? (
                        <>
                            {[...new Set(officeRoles.map(item => item.legalPersonId))].map((legalPersonId, index) => {
                                const companyName = officeRoles.find(item => item.legalPersonId === legalPersonId);
                                const rolesForId = officeRoles.filter(item => item.legalPersonId === legalPersonId);
                                const companyHasMembership = officeRoles.find(item => item.legalPersonId === legalPersonId).hasLegalPersonMembership;
                                const uniqueRoles = [...new Set(rolesForId
                                    .filter(role => !(role.company.entityType === EntityType.EPP && role.role === 'Shareholder'))
                                    .filter(role => role.role !== 'UNDEFINED')
                                    .map(role => role.role)
                                )];

                                return (
                                    <>
                                        {(() => {
                                            return uniqueRoles.length > 0 && companyHasMembership && (
                                                <div key={companyName.id} className='form-row'>
                                                    <div className="text-h2">
                                                        {formatCompanyName(companyName.company?.name)}
                                                    </div>
                                                    <ul>
                                                        {uniqueRoles.map((role, roleIndex) => (
                                                            <li key={`${companyName.id}-${roleIndex}`}>{t(`role.${role}`)}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            );
                                        })()}
                                    </>
                                );
                            })}
                        </>
                    ) : (
                        <div className='form-row'>
                            <p>{t('office_roles.notfound')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OfficeRoles;
