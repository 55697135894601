import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import { getMainContact, searchMainContact, patchMainContact } from './http/HttpClientHelper';
import AutocompleteResults from './AutoComplete/AutoComplete';
import { formatItaaNumber, formatShortDateAsLocalDate } from '../../../../config/formatUtils';
import { Modal, Button } from 'react-bootstrap';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const MainContact = () => {
    const { userDetail } = useOutletContext();
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [officeDetails, setOfficeDetails] = useState(null);
    const [mainContact, setMainContact] = useState(null);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listCandidates, setListCandidates] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [radioButtonIsSelected, setRadioButtonIsSelected] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)
    const [isMainContact, setIsMainContact] = useState(false)

    useEffect(() => {
        const fetchOfficeDetails = async () => {
            const data = await getOffice(legalPersonId);
            setOfficeDetails(data);
        };

        fetchOfficeDetails();
    }, [legalPersonId]);

    useEffect(() => {
        fetchCandidates();
    }, [legalPersonId])

    useEffect(() => {
        const fetchMainContact = async () => {
            setLoading(true);
            const data = await getMainContact(legalPersonId);
            setMainContact(data)
            setLoading(false);
        };
        fetchMainContact();
    }, [legalPersonId, successMessage]);


    const fetchCandidates = async () => {
        setLoading(true);
        const data = await searchMainContact(legalPersonId, "");
        setListCandidates(data);
    }

    if (!officeDetails || !mainContact || !listCandidates || loading) {
        return (
            <div id="main">
                {loading && (
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                )}

                <div id="content">
                    <div className="text-h1">
                        {t('main_contact.title')}
                    </div>
                    <div className="form-group">
                        <div className="form-row">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const { officeName, itaaNumber, dataVerifiedOn } = officeDetails;
    const shortDate = formatShortDateAsLocalDate(dataVerifiedOn);
    const mainContactName = mainContact.mainContact.fullName;
    const userName = userDetail.lastName + " " + userDetail.firstName;

    const handleRadioChange = (event) => {
        const selectedOption = event.target.value;

        if (selectedOption === 'option_open') {
            setShowAdditionalFields(true);
        } else {
            setShowAdditionalFields(false);
        }

        setRadioButtonIsSelected(true);
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
        setIsClickedResult(true);
    };

    const handleNameChange = (event) => {
        if (selectedName !== event.target.value) {
            setSelectedName(event.target.value);
        }
        setIsClickedResult(false);
    };

    const patchMainContactPerson = async () => {
        try {
            const selectedOption = document.querySelector('input[name="main_contact"]:checked').value;

            setLoading(true);

            if (selectedOption === "option_one") {
                await patchMainContact(legalPersonId, userDetail.id);
                setSuccessMessage(t('main_contact.success_message_option_one'));
            }

            if (selectedOption === "option_two") {
                await patchMainContact(legalPersonId, mainContact.mainContact.userId);
                setSuccessMessage(t('main_contact.success_message_option_two'));
            }

            if (selectedOption === "option_open") {
                await patchMainContact(legalPersonId, selectedPerson.naturalPersonId);
                setSuccessMessage(t('main_contact.success_message_option_two'));
            }

        } finally {
            setLoading(false);
            setIsConfirmModalVisible(false);
            window.location.reload();
        }
    }

    const handleSubmit = () => {
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await patchMainContactPerson();
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false)
    }

    return (
        <div id="main">
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton />
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            {userName === mainContactName && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("mandate_application.modal_success_title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t("popup.successUpdate")}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" className="btn-eloket" onClick={handleCloseSuccess}>
                            {t("mandate_application.modal_success_close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Office}
                />

                <div className="text-h1">{t('main_contact.title')}</div>

                <div className="form-group">
                    <div className="form-row">
                        <p><b>{t('main_contact.current_main_contact')} {mainContactName}</b></p>
                    </div>
                    <div className="form-row">
                        <p>{t(userName === mainContactName ? 'main_contact.explanation' : 'main_contact.explanation_alt', { user: userName, mainContact: mainContactName })}</p>
                    </div>

                    <fieldset className="radio-group">
                        {userName === mainContactName ? (
                            <>
                                <div>
                                    <input type='radio' name="main_contact" value="option_one" defaultChecked="true" onChange={handleRadioChange} />
                                    {t('main_contact.option_one')}
                                </div>
                                <div>
                                    <input type='radio' name="main_contact" value="option_open" onChange={handleRadioChange} />
                                    {t('main_contact.option_two')}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <input type='radio' name="main_contact" value="option_one" onChange={handleRadioChange} />
                                    {t('main_contact.option_one_alt', { user: userName })}
                                </div>
                                <div>
                                    <input type='radio' name="main_contact" value="option_two" onChange={handleRadioChange} />
                                    {t('main_contact.option_two_alt', { mainContact: mainContactName })}
                                </div>
                                <div>
                                    <input type='radio' name="main_contact" value="option_open" onChange={handleRadioChange} />
                                    {t('main_contact.option_three_alt')}
                                </div>
                            </>
                        )}
                    </fieldset>

                    {showAdditionalFields && (
                        <div className="form-row">
                            {userName === mainContactName && listCandidates.length === 1 && listCandidates.some(candidate => candidate.contact.fullName === userName) ? (
                                <div className="alert alert-danger" role="alert">
                                    {t('main_contact.warning')}
                                </div>
                            ) : (
                                <div className='form-col col-xs-12 col-sm-8 col-md-4'>
                                    <p className="text-description">{t('main_contact.description_select')}</p>
                                    <div className='controls'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={selectedName}
                                            placeholder=""
                                            autoComplete='off'
                                            onChange={handleNameChange}
                                            onFocus={() => setIsInputFocused(true)}
                                            onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
                                        />
                                        <label className='control-label'>{t('network.search')}</label>
                                    </div>
                                    {!isClickedResult && (
                                        <AutocompleteResults
                                            inputValue={selectedName}
                                            legalPersonId={legalPersonId}
                                            handleSelection={handleAutocompleteSelection}
                                            isInputFocused={isInputFocused}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <footer>
                <div className="button-save-warning-container">
                    <button
                        className="btn btn-eloket"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!radioButtonIsSelected}
                    >
                        {t('main_contact.verify')}
                    </button>
                    {radioButtonIsSelected && <div className="save-warning">{t("main_contact.notSaved")}</div>}
                </div>
            </footer>
        </div>
    );
}

export default MainContact;