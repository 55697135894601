import React, { createContext, useState, useContext, useMemo, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosClient from "../../axiosClient";
import { getUserCompanies, getUserOffices, putUserVisibilities } from "../ELoket/Sidebar/http/HttpClientHelper";
import { loginActionHistory } from "./http/HttpClientHelper";
import { UserActionTypesEnum } from "../../Enums/UserActionTypesEnum";
import showToastMessage from "../Toast/ToastMessage";
import { useTranslation } from "react-i18next";

const CURRENT_USER = "currentUser";
const LOGIN_HISTORY_CREATED = "ActionHistoryCreated";
const UserContext = createContext();
export const useUserProvider = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { isAuthenticated, user, logout, loginWithRedirect, isLoading, getAccessTokenSilently, error } = useAuth0();
    const [curUser, setCurUser] = useState(() => {
        const storedUser = localStorage.getItem(CURRENT_USER);
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const handleLogout = () => {
        clearStorage();
        logout();
    }

    const clearStorage = () => {
        localStorage.clear();
        sessionStorage.clear();
    }

    useEffect(() => {
        const handleCallback = async () => {
            if (isAuthenticated) {
                await handleLoginFromCallback(user);
            }
        };
        handleCallback();
    }, [isAuthenticated]);

    const handleLoginFromCallback = async (user) => {
        if (isAuthenticated && user) {
            const filteredNaturalPersonId = user.externalid;
            const actionHistoryCreated = localStorage.getItem(LOGIN_HISTORY_CREATED);

            // Token
            const accessToken = await getAccessTokenSilently();
            sessionStorage.setItem("accessToken", accessToken);

            // ActionHistory
            if (!actionHistoryCreated) {
                loginActionHistory(filteredNaturalPersonId, UserActionTypesEnum.Login);
                localStorage.setItem(LOGIN_HISTORY_CREATED, "true");
            }

            await handleLogin(filteredNaturalPersonId);
        }
    };

    const handleLoginFromImpersonate = async (naturalPersonId, itaaEmployeeId) => {
        localStorage.removeItem("companies");
        localStorage.removeItem("offices");
        const userDetails = await getUserDetails(naturalPersonId);
        if (userDetails) {
            userDetails.isItaaEmployee = true;
            userDetails.itaaEmployeeId = itaaEmployeeId;
            userDetails.isImpersonated = true;
            setCurUser(userDetails);
            localStorage.setItem("currentUser", JSON.stringify(userDetails));

            putUserVisibilities(naturalPersonId);
            await getUserOffices(naturalPersonId);
            await getUserCompanies(naturalPersonId);

            redirectToHome();
        }
    };

    const handleLogin = async (naturalPersonId) => {
        const currentUser = localStorage.getItem("currentUser");
        if (!currentUser) {
            const userDetails = await getUserDetails(naturalPersonId);
            if (userDetails) {
                setCurUser(userDetails);
                localStorage.setItem("currentUser", JSON.stringify(userDetails));

                putUserVisibilities(naturalPersonId);
                await getUserOffices(naturalPersonId);
                await getUserCompanies(naturalPersonId);

                if (userDetails.language === 'Dutch') {
                    i18n.changeLanguage('nl');
                } else {
                    i18n.changeLanguage('fr');
                }

                redirectToHome();
            }
        }
    };

    const stopImpersonation = async (itaaEmployeeId) => {
        localStorage.removeItem("companies");
        localStorage.removeItem("offices");

        const userDetails = await getUserDetails(itaaEmployeeId);
        if (userDetails) {
            setCurUser(userDetails);
            localStorage.setItem("currentUser", JSON.stringify(userDetails));

            getUserOffices(itaaEmployeeId);
            getUserCompanies(itaaEmployeeId);

            redirectToHome();
        }

        await handleLogin(itaaEmployeeId);
    };

    const getUserDetails = async (userId) => {
        try {
            const response = await axiosClient.get(`/api/Profiles/${userId}/itaadetails`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            sessionStorage.clear();
            showToastMessage(t("errors.fetchUserDetails"));

            setTimeout(() => {
                logout();
            }, 5000);
        }
    };

    const redirectToHome = () => {
        window.location.href = "/";
    };

    return (
        <UserContext.Provider
            value={{
                isAuthenticated,
                curUser,
                loginWithRedirect,
                handleLoginFromCallback,
                handleLoginFromImpersonate,
                stopImpersonation,
                isLoading,
                error,
                handleLogout,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};