import axiosClient from "../../../../../axiosClient";

export const getEstoxCompanies = (naturalPersonId) => {
    return axiosClient
        .get(`/api/Users/${naturalPersonId}/estoxcompanies`)
        .then((response) => response.data);
}

export const getEstoxLink = (naturalPersonId, entityId, entityTypeToGenerateLinkFor, estoxEnvironmentType) => {
    const url = `/api/Users/${naturalPersonId}/estoxlink`;
    const data = {
        naturalPersonId,
        entityId,
        entityTypeToGenerateLinkFor,
        estoxEnvironmentType,
    };

    return axiosClient
        .post(url, data)
        .then((response) => {
            return response.data;
        });
};

export const getExamUrl = (naturalPersonId, examType) => {
    return axiosClient
         .get(`/api/Users/${naturalPersonId}/visibilities/ExamRegistrationUrl`, {
            params: {
                examType: examType
            }
        })
        .then((response) => response.data.data);
}

export const getEstoxExternalProviderShare = (id, callbackUrl) => {
    return axiosClient.post(`/api/Users/estox/${id}/externalProviderShare`,{callbackUrl})
    .then((response) => {
        return response.data;
    });
  };
  