export default class ImpersonationModel {
    constructor(data) {
        this.id = data.id;
        this.itaaNumber = data.itaaNumber;
        this.name = data.name;
        this.hasPortalAccess = data.hasPortalAccess;
        this.itaaQualifications = data.itaaQualifications;
        this.officialTypes = data.officialTypes;
    }

    static fromApiResponse(responseData) {
        return new ImpersonationModel(responseData);
    }
}