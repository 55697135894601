import React, { useState, useEffect } from 'react';
import { getMyDetails, patchMyDetails, getCities, getCountries, getRegisteredOffice, patchNationalNumber } from './http/HttpClientHelper';
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getNaturalPersonInformationValidation, patchNaturalPersonInformationValidation, resetPostponementsNaturalPersonInformationValidation } from '../../../Main/LandingPage/http/HttpClientHelper';
import { Modal, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { ListGroup } from 'react-bootstrap';
import { getCityName } from '../../../../config/translationUtils';
import NaturalPersonInfoHeader from '../../NaturalPersonInfoHeader/NaturalPersonInfoHeader';
import { ValidatePhoneNumber, ValidateRequiredText, ValidateWebsite } from '../../../../config/validationUtils';
import Validation from '../../../Validation/Validation';
import { formatNationalRegistrationNumber } from '../../../../config/formatUtils';

const Data = () => {
    const { userDetail } = useOutletContext();
    const { t, i18n } = useTranslation();
    const userLanguage = i18n.language;
    const [myDetails, setMyDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [naturalPersonInformationData, setNaturalPersonInformationData] = useState({})
    const [isSuccess, SetIsSuccess] = useState(false);
    const [isManualAddress, setIsManualAddress] = useState(true);
    const [validationErrorPhoneOfficeHours, setValidationErrorPhoneOfficeHours] = useState({});
    const [professionalDetailsRequired, setProfessionalDetailsRequired] = useState(true);
    const DEBOUNCE_TIME = 250;

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [showNationalNumberError, setShowNationalNumberError] = useState(false);
    const [showWebsiteValidationError, setShowWebsiteValidationError] = useState(false);

    // Private
    const [filteredPrivateCountries, setFilteredPrivateCountries] = useState([]);
    const [filteredPrivateCities, setFilteredPrivateCities] = useState([]);
    const [countryPrivateDropdown, setCountryPrivateDropdown] = useState(false);
    const [postalcodePrivateDropdown, setPostalcodePrivateDropdown] = useState(false);

    // Public
    const [filteredPublicCountries, setFilteredPublicCountries] = useState([]);
    const [filteredPublicCities, setFilteredPublicCities] = useState([]);
    const [countryPublicDropdown, setCountryPublicDropdown] = useState(false);
    const [postalcodePublicDropdown, setPostalcodePublicDropdown] = useState(false);

    // KBO
    const [error, setError] = useState(false);
    const [loadingKBO, setLoadingKBO] = useState(false);

    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            const details = await getMyDetails(userDetail.id);
            const naturalPersonInformation = await getNaturalPersonInformationValidation(userDetail.id);
            setNaturalPersonInformationData(naturalPersonInformation);
            setMyDetails(details);
        };

        fetchDetails().finally(() => {
            setLoading(false);
        });

        const fetchCountriesAndCities = async () => {
            const countries = await getCountries();
            const cities = await getCities();

            setCities(cities);
            setCountries(countries);
        }
        fetchCountriesAndCities();

        setProfessionalDetailsRequired(userDetail.tracks && userDetail.tracks.length > 0);
    }, [isSuccess, userDetail]);

    useEffect(() => {
        setHasValidationErrors(validateRequiredFields());
    }, [myDetails]);

    const handleCountryChange = async (event) => {
        const { name, value } = event.target;

        setMyDetails(prevDetails => ({ ...prevDetails, [name]: value }));

        // Private
        if (name === 'privateDetails_Country') {
            const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
            setFilteredPrivateCountries(filtered);
            setCountryPrivateDropdown(true);
        }

        // Public 
        if (name === 'professionalDetails_Country') {
            const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
            setFilteredPublicCountries(filtered);
            setCountryPublicDropdown(true);
        }
        setValidationAndChanges();
    };

    const handlePostalCodeChange = async (event) => {
        const { name, value } = event.target;

        setMyDetails(prevDetails => ({ ...prevDetails, [name]: value }));

        // Private
        if (name === 'privateDetails_PostalCode') {
            setPostalcodePrivateDropdown(true);
            const postalCodeFilter = cities.filter(c =>
                c.country.value == myDetails.privateDetails_Country &&
                (c.postalCode.toLowerCase().includes(value.toLowerCase())
                    || c.dutchName.toLowerCase().includes(value.toLowerCase())
                    || c.englishName.toLowerCase().includes(value.toLowerCase())
                    || c.frenchName.toLowerCase().includes(value.toLowerCase())));
            setFilteredPrivateCities(postalCodeFilter);
        }

        // Public
        if (name === 'professionalDetails_PostalCode') {
            setPostalcodePublicDropdown(true);
            const postalCodeFilter = cities.filter(c =>
                c.country.value == myDetails.privateDetails_Country &&
                (c.postalCode.toLowerCase().includes(value.toLowerCase())
                    || c.dutchName.toLowerCase().includes(value.toLowerCase())
                    || c.englishName.toLowerCase().includes(value.toLowerCase())
                    || c.frenchName.toLowerCase().includes(value.toLowerCase())));
            setFilteredPublicCities(postalCodeFilter);
        }
        setValidationAndChanges();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMyDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        setValidationAndChanges();
    };

    const handleKBOChange = (event) => {
        const { name, value } = event.target;
        setMyDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        (async () => {
            // Check if the input is complete (BE followed by 10 digits)
            if (/^BE\d{10}$/.test(value)) {
                setLoadingKBO(true);
                var data = await getRegisteredOffice(value.substring(2), ConvertToAbbreviation(userDetail?.language)); // Remove 'BE' and pass only the digits & language
                const city = cities.find(city => city?.postalCode === data?.registeredOffice?.postalCode);

                if (data === null || data.length === 0) {
                    setError(true);
                } else {
                    setError(false);
                    setMyDetails(prevDetails => ({
                        ...prevDetails,
                        professionalDetails_Street: data?.registeredOffice?.street + " " + data?.registeredOffice?.number || "",
                        professionalDetails_PostalCode: city?.postalCode || "",
                        professionalDetails_CityId: city?.id || "",
                        professionalDetails_City: getCityName(city, userLanguage),
                        professionalDetails_Country: city?.country?.value || ""
                    }));
                }

                setLoadingKBO(false);
            }
        })();
        setValidationAndChanges();
    };

    const ConvertToAbbreviation = (language) => {
        var lowercaseLanguage = language.toLowerCase();

        if (lowercaseLanguage === "dutch") {
            return "nl";
        }
        if (lowercaseLanguage === "french") {
            return "fr";
        }
        if (lowercaseLanguage === "english") {
            return "en";
        }

        // default langauge
        return "en"
    }

    const handlePrivateItemClick = (item, field) => {
        // Private
        if (field === 'privateDetails_Country') {
            setMyDetails(prevDetails => ({
                ...prevDetails,
                [field]: item.name,
                'privateDetails_PostalCode': '',
                'privateDetails_City': '',
            }));
            const filtered = cities.filter(city => city.country.value.toLowerCase().includes(item.name.toLowerCase()));
            setFilteredPrivateCities(filtered);
        }
        if (field === 'privateDetails_PostalCode') {
            setMyDetails(prevDetails => ({
                ...prevDetails,
                [field]: item.postalCode,
                'privateDetails_CityId': item.id,
                'privateDetails_City': getCityName(item, userLanguage),
            }));
            setPostalcodePrivateDropdown(false);
        }

        setCountryPrivateDropdown(false);
        setValidationAndChanges();
    };

    const handlePublicItemClick = (item, field) => {
        // Public
        if (field === 'professionalDetails_Country') {
            setMyDetails(prevDetails => ({
                ...prevDetails,
                [field]: item.name,
                'professionalDetails_PostalCode': '',
                'professionalDetails_City': '',
            }));
            const filtered = cities.filter(city => city.country.value.toLowerCase().includes(item.name.toLowerCase()));
            setFilteredPublicCities(filtered);
        }
        if (field === 'professionalDetails_PostalCode') {
            setMyDetails(prevDetails => ({
                ...prevDetails,
                [field]: item.postalCode,
                'professionalDetails_CityId': item.id,
                'professionalDetails_City': getCityName(item, userLanguage),
            }));
            setPostalcodePublicDropdown(false);
        }

        setCountryPublicDropdown(false);
        setValidationAndChanges();
    };

    const handleCloseSuccess = () => {
        window.location.reload()
    };

    const handleRadioChange = (event) => {
        setError(false);
        setIsManualAddress(event.target.value === 'False');
        setValidationAndChanges();
    };

    const handleNaturalPersonConfirmClick = async () => {
        if (!myDetails.nationalNumber) {
            setShowNationalNumberError(true);
        }
        else if (!ValidateWebsite(myDetails.professionalDetails_Webpage)) {
            setShowWebsiteValidationError(true);
        }
        else {
            setLoading(true);
            var result = await patchNationalNumber(userDetail.id, myDetails.nationalNumber);
            if (result !== '500') {
                await patchNaturalPersonInformationValidation(userDetail.id, {
                    hasIgnored: false,
                    hasConfirmed: true,
                });
                //sanitizeDetails();
                setLoading(false);
                window.location.reload();
            }
            else {
                setLoading(false);
                setIsConfirmModalVisible(false);
                setHasChanges(false);

                setShowNationalNumberError(true);
            }
        }
    }

    const setValidationAndChanges = () => {
        setHasValidationErrors(validateRequiredFields());
        setHasChanges(true);
    }

    const handleDataChange = async () => {
        setLoading(true);

        var result = await patchNationalNumber(userDetail.id, myDetails.nationalNumber);
        if (result !== '500') {
            await patchMyDetails(userDetail.id, myDetails);
            SetIsSuccess(true);
        }
        else {
            setShowNationalNumberError(true);
        }

        setHasChanges(false);
        setLoading(false);
        setIsConfirmModalVisible(false);
    };

    const validateRequiredFields = () => {
        let validationErrorCount = 0;
        ValidateRequiredText(myDetails.privateDetails_Street) || validationErrorCount++;
        ValidateRequiredText(myDetails.privateDetails_Country) || validationErrorCount++;
        ValidateRequiredText(myDetails.privateDetails_PostalCode) || validationErrorCount++;
        ValidateRequiredText(myDetails.nationalNumber) || validationErrorCount++;

        if (professionalDetailsRequired) {
            ValidateRequiredText(myDetails.professionalDetails_Street) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails_Country) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails_PostalCode) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails_City) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails_City) || validationErrorCount++;
            ValidateRequiredText(myDetails.professionalDetails_PublicRegisterEmailAddress) || validationErrorCount++;
        }

        if (validationErrorCount > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const sanitizeDetails = () => {
        const sanitizedDetails = { ...myDetails };
        for (const key in sanitizedDetails) {
            if (sanitizedDetails[key] === null) {
                sanitizedDetails[key] = "";
            }
        }
        setMyDetails(sanitizedDetails);
        setIsConfirmModalVisible(true);
    }

    const handleSubmit = () => {
        const validationErrors = {};
        ValidatePhoneNumber(myDetails.professionalDetails_TelephoneNumber) || (validationErrors.phoneOfficeHours = t('validation.phoneNumber'));
        setValidationErrorPhoneOfficeHours(validationErrors);

        if (!ValidateWebsite(myDetails.professionalDetails_Webpage)) {
            validationErrors.webpage = '';
            setShowWebsiteValidationError(true);
        }

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        //sanitizeDetails();
        setShowWebsiteValidationError(false);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await handleDataChange();
    };

    const handleResetPostponementsClick = async () => {
        setLoading(true);
        await resetPostponementsNaturalPersonInformationValidation(userDetail.id);
        setLoading(false);
        window.location.reload();
    }

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isSuccess && ( // Ensure that modal is displayed when isSuccess is true
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('data.modal_success_text')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <NaturalPersonInfoHeader userDetail={userDetail} />
            {!hasValidationErrors && (
                <div className="form-group">
                    <div className="form-row row">
                        <div className="form-col col-12">
                            <span className="text-danger">
                                {t('validation.allRequiredFields')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className="form-grid">
                <div className='form-row row'>
                    <div className='col-md-6 col-xs-12'>
                        <div className="text-h1">
                            {t('data.contactDetailsPrivate')}
                        </div>
                        <div className="form-group">
                            <form action="#" className="form-overview form-detect-unsaved" id="form" method="post" onSubmit={handleSubmit} noValidate>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_Street"
                                                value={myDetails?.privateDetails_Street || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className="control-label required">{t('data.street')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_Country"
                                                value={myDetails?.privateDetails_Country || ""}
                                                onBlur={() => setTimeout(() => setCountryPrivateDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handleCountryChange}
                                                onChange={handleCountryChange}
                                            />
                                            <label className="control-label required">{t('data.country')}</label>
                                            {countryPrivateDropdown && (
                                                <ListGroup className="list-group-data">
                                                    <>
                                                        {filteredPrivateCountries.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePrivateItemClick(item, 'privateDetails_Country')}>
                                                                {item.name}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_PostalCode"
                                                value={myDetails?.privateDetails_PostalCode || ""}
                                                onBlur={() => setTimeout(() => setPostalcodePrivateDropdown(false), DEBOUNCE_TIME)}
                                                disabled={!myDetails?.privateDetails_Country}
                                                onFocus={handlePostalCodeChange}
                                                onChange={handlePostalCodeChange}
                                            />
                                            <label className="control-label required">{t('data.postalcode')}</label>
                                            {postalcodePrivateDropdown && (
                                                <ListGroup>
                                                    <>
                                                        {filteredPrivateCities.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePrivateItemClick(item, 'privateDetails_PostalCode')}>
                                                                {item.postalCode} {getCityName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                        {filteredPrivateCities.length === 0 && myDetails?.privateDetails_PostalCode.length === 0 &&
                                                            (<ListGroup.Item>
                                                                {t('data.noResult')}
                                                            </ListGroup.Item>
                                                            )}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_City"
                                                value={myDetails?.privateDetails_City || ""}
                                                disabled
                                            />
                                            <label className="control-label required">{t('data.city')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_TelephoneNumber"
                                                value={myDetails?.privateDetails_TelephoneNumber || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.phone')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="privateDetails_CellphoneNumber"
                                                value={myDetails?.privateDetails_CellphoneNumber || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.mobilephone')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="nationalNumber"
                                                value={formatNationalRegistrationNumber(myDetails?.nationalNumber || "")}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label required'>{t('data.nationalNumber')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        {showNationalNumberError && (
                                            <span className='warning-text'>{t('data.wrong_nationalnumber')}</span>
                                        )}
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <div className="text-h1">
                            {t('data.contactDetails')}
                        </div>
                        <div className="form-group">
                            <form action="#" className="form-overview form-detect-unsaved" id="form" method="post" onSubmit={handleSubmit}>
                                <div className="form-row row mb-3">
                                    <div className="form-col col-md-12" style={{ width: 'auto' }}>
                                        <label>{t('data.select_source_professional_contactdetails')}</label><br />
                                        <span style={{ color: '#550A21' }}>
                                            {t('data.kbo_text')}
                                        </span>
                                    </div>

                                    <div className="controls" style={{ width: '500px' }} >
                                        <input
                                            autoComplete="off"
                                            type="radio"
                                            value="True"
                                            checked={!isManualAddress}
                                            onChange={handleRadioChange}
                                        />
                                        Geef het ondernemings-of vestiginseenheidsnummer in
                                        <br />
                                        <input
                                            autoComplete="off"
                                            type="radio"
                                            value="False"
                                            checked={isManualAddress}
                                            onChange={handleRadioChange}
                                        />
                                        Geef het adres manueel in
                                    </div>
                                </div>
                                {!isManualAddress && (
                                    <div className="form-row company-number-kbo row">
                                        <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="controls">
                                                <InputMask
                                                    mask="BE9999999999"
                                                    maskChar={null}
                                                    className="form-control"
                                                    type="text"
                                                    name="professionalDetails_AddressFromKBO"
                                                    value={myDetails?.professionalDetails_AddressFromKBO || ""}
                                                    onChange={handleKBOChange}
                                                />
                                                <label className='control-label'>{t('data.companynumber')}</label>
                                                {loadingKBO && <div className="loader-small" />}
                                                {!loadingKBO && error && (<span className="error-field">{t('data.error')}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_Street"
                                                value={myDetails?.professionalDetails_Street || ""}
                                                onChange={handleInputChange}
                                                disabled={!isManualAddress}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.street')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                inputMode='none'
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_Country"
                                                value={myDetails?.professionalDetails_Country || ""}
                                                onBlur={() => setTimeout(() => setCountryPublicDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handleCountryChange}
                                                onChange={handleCountryChange}
                                                disabled={!isManualAddress}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.country')}</label>
                                            {countryPublicDropdown && (
                                                <ListGroup className="list-group list-group-data">
                                                    <>
                                                        {filteredPublicCountries.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePublicItemClick(item, 'professionalDetails_Country')}>
                                                                {item.name}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_PostalCode"
                                                value={myDetails?.professionalDetails_PostalCode || ""}
                                                onBlur={() => setTimeout(() => setPostalcodePublicDropdown(false), DEBOUNCE_TIME)}
                                                onFocus={handlePostalCodeChange}
                                                onChange={handlePostalCodeChange}
                                                disabled={!isManualAddress || !myDetails?.professionalDetails_Country}
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.postalcode')}</label>
                                            {postalcodePublicDropdown && (
                                                <ListGroup>
                                                    <>
                                                        {filteredPublicCities.map((item, index) => (
                                                            <ListGroup.Item key={index} onClick={() => handlePublicItemClick(item, 'professionalDetails_PostalCode')}>
                                                                {item.postalCode} {getCityName(item, userLanguage)}
                                                            </ListGroup.Item>
                                                        ))}
                                                        {filteredPublicCities.length === 0 && myDetails?.professionalDetails_PostalCode.length === 0 &&
                                                            (<ListGroup.Item>
                                                                {t('data.noResult')}
                                                            </ListGroup.Item>
                                                            )}
                                                    </>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_City"
                                                value={myDetails?.professionalDetails_City || ""}
                                                onChange={handleInputChange}
                                                disabled
                                            />
                                            <label className={`control-label ${professionalDetailsRequired ? 'required' : ''}`}>{t('data.city')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_TelephoneNumber"
                                                value={myDetails?.professionalDetails_TelephoneNumber || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.phone_officehours')}</label>
                                            <Validation errors={validationErrorPhoneOfficeHours} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <span>{t('data.text_public_register_phone_web')}</span>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_CellphoneNumber"
                                                value={myDetails?.professionalDetails_CellphoneNumber || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.mobilephone')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="controls">
                                            <input
                                                autoComplete="off"
                                                className="form-control masked-kbo-input"
                                                type="text"
                                                name="professionalDetails_Webpage"
                                                value={myDetails?.professionalDetails_Webpage || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label className='control-label'>{t('data.web')}</label>
                                            {showWebsiteValidationError && (
                                                <span className="warning-text">
                                                    {t('validation.webpage')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <span>{t('data.text_public_register_email')}</span>
                                </div>
                                <div className="form-row">
                                    <div className="form-col col-1-1">
                                        {naturalPersonInformationData?.shouldConfirm ?
                                            <div className="controls">
                                                <input
                                                    autoComplete="off"
                                                    className="form-control masked-kbo-input"
                                                    type="text"
                                                    name="professionalDetails_PublicRegisterEmailAddress"
                                                    value={myDetails?.professionalDetails_PublicRegisterEmailAddress || ""}
                                                    onChange={handleInputChange}
                                                />
                                                <label className='control-label required'>{t('data.public_register_email')}</label>
                                            </div>
                                            :
                                            <div className="controls">
                                                <input
                                                    autoComplete="off"
                                                    className="form-control masked-kbo-input"
                                                    type="text"
                                                    name="professionalDetails_PublicRegisterEmailAddress"
                                                    value={myDetails?.professionalDetails_PublicRegisterEmailAddress || ""}
                                                    onChange={handleInputChange}
                                                />
                                                <label className='control-label required'>{t('data.public_register_email')}</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="button-container-personal-data">
                        {(naturalPersonInformationData == null || (!naturalPersonInformationData?.shouldConfirm && !naturalPersonInformationData?.validateNationalNumber) && (
                            <div className="button-save-warning-container">
                                <button className="btn btn-eloket" type="submit" onClick={handleSubmit} disabled={!hasValidationErrors || !hasChanges || error}>
                                    <i className="fa fa-check mr-2"></i>{t('data.save')}
                                </button>
                            </div>
                        ))}
                        {(naturalPersonInformationData?.shouldConfirm || naturalPersonInformationData?.validateNationalNumber) && (
                            <>
                                <div className="button-save-warning-container">
                                    <button className="btn btn-eloket" onClick={handleNaturalPersonConfirmClick} disabled={!hasValidationErrors || error}>
                                        <i className="fa fa-calendar-check-o mr-2"></i> {t('confirmation.title')}
                                    </button>
                                    {userDetail.isImpersonated && (
                                        <span onClick={handleResetPostponementsClick}>
                                            <button className="btn btn-eloket">
                                                <i className="fa fa-undo mr-2"></i>{" "}
                                                {t("confirmation.resetPostponements")} {naturalPersonInformationData.currentPostponements}/{naturalPersonInformationData.maxPostponements}
                                            </button>
                                        </span>
                                    )}
                                </div>

                            </>
                        )}
                        {hasChanges && <div className="save-warning"> {t("data.notSaved")} </div>}
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Data;