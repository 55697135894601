export default {
  nl: {
    MainContactPerson: "Hoofdcontactpersoon",
    Employee: "Medewerker",
    Manager: "Bestuurder",
    QualityResponsible: "Kwaliteitsverantwoordelijke",
    Director: "Bestuurder",
    AWW_AMLCO: "AMLCO",
    AWW_VPHN: "VPHN",
    AML_AMLCO: "Antiwitwas AMLCO verantwoordelijke",
    AML_VPHN:"Verantwoordelijke persoon op het Hoogste Niveau (VPHN)",
    Portal_Estox:"eStox gebruiker",
    Portal_INIS:"Opleidingen gebruiker",
    OfficeComposition: "Samenstelling kantoor",
    PermanentRepresentative: "Vaste vertegenwoordiger",
    Portal_CoAdministrator: "Medebeheerder",
    Portal_MandateUser:"Mandaten gebruiker",
    Chairman:"Voorzitter",
    Shareholder:"Vennoot",
    Portal_EstoxAdmin: "Estox administrator",
    Portal_MandateAdmin: "Mandaten administrator"
  },
  fr: {
    MainContactPerson: "Personne de contact principale",
    Employee: "Collaborateur",
    Manager: "Gérant",
    QualityResponsible: "Responsable qualité",
    Director: "Administrateur",
    AWW_AMLCO: "AMLCO",
    AWW_VPHN: "PRHN",
    AML_AMLCO: "Responsable AMLCO anti-blanchiment",
    AML_VPHN:"Personne Responsable au plus Haut Niveau (PRHN)",
    Portal_Estox:"Utilisateur eStox",
    Portal_INIS:"Utilisateur formations",
    OfficeComposition: "Composition du cabinet",
    PermanentRepresentative: "Représentant permanent",
    Portal_CoAdministrator: "Co-administrateur",
    Portal_MandateUser:"Utilisateur mandats",
    Chairman:"Président",
    Shareholder:"Associé",
    Portal_EstoxAdmin: "Administrateur eStox",
    Portal_MandateAdmin: "Administrateur mandats",
  },
};
