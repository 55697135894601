export default {
  nl: {
    title:
      "Hoofdcontactpersoon verantwoordelijk voor de contacten met het ITAA.",
    explanation:
      "Bent u, {{user}}, de hoofdcontactpersoon verantwoordelijk voor de contacten met het ITAA?",
    option_yes:"Ja",
    option_no:"Nee",
    option_one: "Ja, ik ben verantwoordelijk",
    option_two: "Neen, iemand anders is verantwoordelijk",
    explanation_alt:
      "Momenteel is {{mainContact}} verantwoordelijk voor de contacten met het ITAA. Wenst u dit aan te passen en uzelf aan te duiden?",
    option_one_alt: "Ja, {{user}} toewijzen.",
    option_two_alt: "Nee, {{mainContact}} behouden.",
    option_three_alt: "Nee, nieuw hoofdcontact aanduiden.",
    description_select:
      "Wie is de verantwoordelijke voor de contacten met het ITAA?",
    verify: "Bevestigen",
    current_main_contact:
      "De hoofdcontactpersoon, die verantwoordelijk is voor de contacten met het ITAA en de informatie onder de rubrieken onderneming(en) en kanto(o)r(en), is: ",
    warning:
      "Enkel u bent als hoofdcontactpersoon gekend voor deze vennootschap. Indien u niet de verantwoordelijke bent dient u contact op te nemen met servicedesk@itaa.be.",
    success_message_option_one:
      "U bent aangesteld als bestuurder verantwoordelijk voor de contacten met het ITAA",
    success_message_option_two:
      "U bent niet langer hoofdcontactpersoon verantwoordelijk ...",
    main_contact:"Hoofdcontactpersoon",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen..."
  },
  fr: {
    title: "Personne de contact principale responsable des contacts avec l'ITAA.",
    explanation:
      "Êtes-vous, {{user}}, la personne de contact principale responsable des contacts avec l'ITAA ?",
    option_yes:"oui",
    option_no:"Non",
    option_one: "Oui, je suis responsable",
    option_two: "Non, quelqu'un d'autre est responsable",
    explanation_alt:
      "Actuellement, {{mainContact}} est responsable des contacts avec l'ITAA. Souhaitez-vous modifier cela et vous désigner vous-même?",
    option_one_alt: "Oui, attribuer à {{user}}.",
    option_two_alt: "Non, conserver {{mainContact}}.",
    option_three_alt: "Non, indiquez le nouveau contact principal.",
    description_select: "Qui est responsable pour les contacts avec l'ITAA ?",
    verify: "Confirmer",
    current_main_contact:
      "La personne de contact principale, qui est responsable des contacts avec l'ITAA et des informations sous les rubriques entreprise(s) et cabinet(s), est",
    warning:
      "Vous êtes le seul connu comme personne de contact principale pour cette entreprise. Si vous n'êtes pas la personne responsable, veuillez contacter servicedesk@itaa.be.",
    success_message_option_one:
      "Vous êtes désigné comme administrateur responsable des contacts avec l’ITAA",
    success_message_option_two:
      "Vous n’êtes plus la personne de contact principale responsable...",
    main_contact:"La personne de contact principale",
    notSaved: "Vos modifications n’ont pas encore été enregistrées..."
  },
};
