export default {
  nl: {
    itaaNumber: "ITAA Nummer",
    name: "Naam",
    accessPortal: "Toegang portaal",
    yes: "Ja",
    no: "Nee",
    itaaTitle: "ITAA Titel",
    itaaQualification: "ITAA Hoedanigheid",
    officialType: "Officieel Type",
    action: "Impersonate",
    detail: "Detail",
    previous: "Vorige",
    next: "Volgende",
    page:"Pagina"
  },
  fr: {
    itaaNumber: "Numéro ITAA",
    name: "Nom",
    yes: "Oui",
    no: "Non",
    accessPortal: "Access portal",
    itaaTitle: "Titre ITAA",
    itaaQualification: "Qualité ITAA",
    officialType: "Type Officiel",
    action: "Usurpation d'identité",
    detail: "Détail",
    previous: "Précédent",
    next: "Suivante",
    page: "Page"
  },
};
