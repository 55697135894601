import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import { getUserImpersonations } from './http/HttpClientHelper';
import ImpersonationModel from "./data/ImpersonationModel";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { formatItaaNumber } from '../../../config/formatUtils';
import { useUserProvider } from '../../Auth/UserProvider';
import QualificationTableCell from '../../ELoket/MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../ELoket/MembershipInformation/OfficialTypeTableCell';

const Impersonation = () => {
    const [impersonations, setImpersonations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPageSize, setMaxPageSize] = useState(0);
    const [response, setResponse] = useState({});
    const { t } = useTranslation();
    const { searchQuery, userDetail, isMobile, setSearchQuery } = useOutletContext();
    const [loading, setLoading] = useState(true);
    const { handleLoginFromImpersonate } = useUserProvider();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userDetail.isItaaEmployee) {
            navigate('/');
        }
    }, [userDetail, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let responseData = {};
            let impersonationsData = [];
            let filteredImpersonations = [];

            responseData = await getUserImpersonations(currentPage, searchQuery);
            impersonationsData = responseData.currentPage.map((item) =>
                ImpersonationModel.fromApiResponse(item)
            );

            filteredImpersonations = impersonationsData.filter(
                (item) => item.id !== userDetail.id
            );

            setImpersonations(filteredImpersonations);
            setResponse(responseData);

            if (filteredImpersonations.length > 0 && responseData.pageSize) {
                const calculatedMaxPages = Math.ceil(filteredImpersonations.length / responseData.pageSize);
                setMaxPageSize(calculatedMaxPages);
            } else if (responseData.totalItems && responseData.pageSize) {
                const calculatedMaxPages = Math.ceil(responseData.totalItems / responseData.pageSize);
                setMaxPageSize(calculatedMaxPages);

            } else {
                setMaxPageSize(1);
            }
            setLoading(false);
        };

        fetchData();
    }, [currentPage, searchQuery, userDetail]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleImpersonate = async (selectedUserId) => {
        setLoading(true);
        await handleLoginFromImpersonate(selectedUserId, userDetail.id);
        setLoading(false);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        setCurrentPage(1); // Reset to first page on new search
    };

    const filteredImpersonations = impersonations.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div id="table" className="container">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='row'>
                {isMobile &&
                    <div className='search-bar'>
                        <input
                            className="form-control"
                            placeholder={t('navbar.search')}
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                }
            </div>

            <Table responsive>
                <thead>
                    <tr>
                        <th>{t('impersonation.itaaNumber')}</th>
                        <th>{t('impersonation.name')}</th>
                        {!isMobile && (
                            <>
                                <th>{t('impersonation.accessPortal')}</th>
                                <th>{t('impersonation.itaaQualification')}</th>
                                <th>{t('impersonation.officialType')}</th>
                            </>
                        )}
                        <th className='lastColumn'>{t('impersonation.action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredImpersonations.map((item, index) => (
                        <tr key={index}>
                            <td>{formatItaaNumber(item.itaaNumber)}</td>
                            <td>{item.name}</td>
                            <td>{item.hasPortalAccess ? t('impersonation.yes') : t('impersonation.no')}</td>
                            {!isMobile && (
                                <>
                                    <QualificationTableCell
                                        item={item} />
                                    <OfficialTypeTableCell
                                        item={item} />
                                </>
                            )}
                            <td className='lastColumn'>
                                <Button className="btn btn-home" variant="primary" onClick={() => handleImpersonate(item.id)}>
                                    {t('impersonation.detail')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="d-flex justify-content-center my-3">
                <span>{t('impersonation.page')} {currentPage}</span>
            </div>
            <Pagination className="justify-content-center">
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={!response.hasPreviousPage}
                >
                    {t('impersonation.previous')}
                </Pagination.Prev>
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!response.hasNextPage}
                >
                    {t('impersonation.next')}
                </Pagination.Next>
            </Pagination>

        </div>
    );
};

export default Impersonation;
