import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainLayout from './components/Main/MainLayout';
import LandingPage from './components/Main/LandingPage/LandingPage';
import Impersonation from './components/Main/Impersonation/Impersonation';
import ELoketLayout from './components/ELoket/ELoketLayout';
import Data from './components/ELoket/Profile/Data/Data';
import ItaaData from './components/ELoket/Profile/ItaaData/ItaaData';
import PersonalData from './components/ELoket/Profile/PersonalData/PersonalData';
import BasicData from './components/ELoket/Company/BasicData/BasicData';
import DataAtItaa from './components/ELoket/Company/DataAtItaa/DataAtItaa';
import BeExcellent from './components/ELoket/Office/BeExcellent/BeExcellent';
import OfficeComposition from './components/ELoket/Office/OfficeComposition/OfficeComposition';
import MainContact from './components/ELoket/Office/MainContact/MainContact';
import OfficeCoAdministrator from './components/ELoket/Office/CoAdministrator/OfficeCoAdministrator';
import QualityResponsible from './components/ELoket/Office/QualityResponsible/QualityResponsible';
import AntiMoneyLaunderingOfficers from './components/ELoket/Office/AntiMoneyLaunderingOfficers/AntiMoneyLaunderingOfficers'
import AccessTools from './components/ELoket/Office/AccessTools/AccessTools'
import DirectorAndPartner from './components/ELoket/Company/DirectorAndPartner/DirectorAndPartner';
import Network from './components/ELoket/Company/Network/Network';
import Clientele from './components/ELoket/Company/Clientele/Clientele';
import Certificate from './components/Certificate/Certificate';
import MyProfile from './components/ELoket/NavigationPages/NavigationPageMyProfile/MyProfile';
import NavCompanies from './components/ELoket/NavigationPages/NavigationPageItaaCompanies/NavCompanies';
import NavOffices from './components/ELoket/NavigationPages/NavigationPageOffices/NavOffices';
import InfoMessage from './components/Main/InfoMessage/Overview';
import Quality from './components/Main/Quality/Quality';
import Missions from './components/ELoket/Missions/missions';
import ErrorBoundary from './components/Main/Error/ErrorBoundary'
import MyDdata from './components/ELoket/NavigationPages/NavigationPageMyProfile/MyData';
import MyItaaData from './components/ELoket/NavigationPages/NavigationPageMyProfile/MyItaaData';
import ProfileNetworks from './components/ELoket/Profile/ItaaData/ProfileNetworks/ProfileNetworks';
import Insurance from './components/ELoket/Profile/Insurance/Insurance';
import ProfileGroup from './components/ELoket/Profile/ItaaData/ProfileGroups/ProfileGroups';
import OfficeRoles from './components/ELoket/Profile/OfficeRoles/OfficeRoles';
import ResetPassword from './components/Main/ResetPassword/ResetPassword';
import CompanyGeneralData from './components/ELoket/NavigationPages/companies/CompanyGeneralData'
import NavOfficeDetail from './components/ELoket/NavigationPages/NavigationPageOffices/NavOfficeDetail';
import NavCompaniesDetail from './components/ELoket/NavigationPages/NavigationPageCompaniesDetail/NavCompaniesDetail';
import CompanyInsurance from './components/ELoket/Company/Insurance/CompanyInsurance';
import ItaaDataDetails from './components/ELoket/Company/ItaaDataDetails/ItaaDataDetails';
import AuthError from './components/Main/Error/AuthError';
import SidebarLayout from './components/SidebarLayout';
import { setLogoutHandler } from './axiosClient';
import { useUserProvider } from "./components/Auth/UserProvider";
import EstoxExternalProviderShare from './components/Main/LandingPage/Estox/EstoxExternalProviderShare';

const userDetail = JSON.parse(localStorage.getItem('currentUser')) || {};

function App() {
    const { handleLogout } = useUserProvider();

    React.useEffect(() => {
        setLogoutHandler(handleLogout);
    }, [handleLogout]);

    const isMobile = useMediaQuery({ maxWidth: 768 }) || false;
    return (
        <Router>
            <Routes>

                <Route path="/" element={<MainLayout userDetail={userDetail} />}>
                    <Route index element={<LandingPage userDetail={userDetail} />} />
                    <Route path="impersonation" element={<Impersonation />} />
                    <Route path="infomessage/overview" element={<InfoMessage />} />
                    <Route path="quality" element={<Quality />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="*" element={<ErrorBoundary />} />
                </Route>
{/* Voorlopige disabled door CORS error
                <Route path="estox/externalprovidershare" element={<SidebarLayout userDetail={userDetail} />}>
                    <Route path='*'index element={<EstoxExternalProviderShare userDetail={userDetail} />} />
                </Route>
*/ }
                <Route path="infomessage/overview" element={<SidebarLayout userDetail={userDetail} />}>
                    <Route index element={<InfoMessage userDetail={userDetail} />} />
                </Route>

                <Route path="reset-password" element={<SidebarLayout userDetail={userDetail} />}>
                    <Route index element={<ResetPassword userDetail={userDetail} />} />
                </Route>

                <Route path="certificate" element={<SidebarLayout userDetail={userDetail} />}>
                    <Route index element={<Certificate />} />
                </Route>

                <Route path="eloket/*" element={<ELoketLayout userDetail={userDetail} />} >
                    <Route path="myprofile" element={<MyProfile userDetail={userDetail} isMobile={isMobile} />} />
                    <Route path="myprofile/itaadata/:natPersonId" element={<MyItaaData userDetail={userDetail} isMobile={isMobile} />} />
                    <Route path="myprofile/basicdata/:natPersonId" element={<MyDdata isMobile={isMobile} userDetail={userDetail} />} />
                    <Route path="myitaadata" element={<MyItaaData userDetail={userDetail} />} />
                    <Route path="companies" element={<NavCompanies userDetail={userDetail} isMobile={isMobile}/>} />
                    <Route path="offices" element={<NavOffices userDetail={userDetail} isMobile={isMobile} />} />
                    <Route path="data" element={<Data />} />
                    <Route path="insurance" element={<Insurance />} />
                    <Route path="personaldata" element={<PersonalData />} />
                    <Route path="company/:legalPersonId" element={<NavCompaniesDetail userDetail={userDetail} />} />
                    <Route path="company/basicdata/general/:legalPersonId" element={<CompanyGeneralData userDetail={userDetail} />} />
                    <Route path="company/basicdata/contact/:legalPersonId" element={<BasicData userDetail={userDetail} />} />
                    <Route path="company/basicdata/insurance/:legalPersonId" element={<CompanyInsurance userDetail={userDetail} />} />
                    <Route path="company/itaadata/details/:legalPersonId" element={<ItaaDataDetails userDetail={userDetail} />} />
                    <Route path="company/itaadata/general/:legalPersonId" element={<DataAtItaa userDetail={userDetail} />} />
                    <Route path="company/itaadata/clientele/:legalPersonId" element={<Clientele userDetail={userDetail} />} />
                    <Route path="company/itaadata/directorandpartner/:legalPersonId" element={<DirectorAndPartner userDetail={userDetail} isMobile={isMobile} />} />
                    <Route path="company/itaadata/network/:legalPersonId" element={<Network userDetail={userDetail} />} />
                    <Route path="company/itaadata/coadministrator/:legalPersonId" element={<OfficeCoAdministrator userDetail={userDetail} isMobile={isMobile} />} />
                    <Route path="company/itaadata/maincontact/:legalPersonId" element={<MainContact userDetail={userDetail} />} />
                    <Route path="office/:legalPersonId" element={<NavOfficeDetail userDetail={userDetail} />} />
                    <Route path="office/beexcellent/:legalPersonId" element={<BeExcellent userDetail={userDetail} />} />
                    <Route path="office/officecomposition/:legalPersonId" element={<OfficeComposition userDetail={userDetail} isMobile={isMobile} />} ></Route>
                    <Route path="office/maincontact/:legalPersonId" element={<MainContact userDetail={userDetail} />} ></Route>
                    <Route path="office/coadministrator/:legalPersonId" element={<OfficeCoAdministrator userDetail={userDetail} isMobile={isMobile} />} ></Route>
                    <Route path="office/qualityresponsible/:legalPersonId" element={<QualityResponsible userDetail={userDetail} isMobile={isMobile} />} ></Route>
                    <Route path="office/antimoneylaunderingofficers/:legalPersonId" element={<AntiMoneyLaunderingOfficers userDetail={userDetail} isMobile={isMobile} />} ></Route>
                    <Route path="office/accesstools/:legalPersonId" element={<AccessTools userDetail={userDetail} isMobile={isMobile} />} ></Route>
                    <Route path="missions" element={<Missions userDetail={userDetail} />} ></Route>
                    <Route path="networks" element={<ProfileNetworks userDetail={userDetail} />} ></Route>
                    <Route path="officeroles" element={<OfficeRoles userDetail={userDetail} />} ></Route>
                    <Route path="itaadata/general" element={<ItaaData userDetail={userDetail} />} />
                    <Route path="itaadata/groups" element={<ProfileGroup userDetail={userDetail} />} ></Route>

                    <Route path="*" element={<ErrorBoundary />} />
                </Route>

                <Route path="auth-error" element={<AuthError />} />
                <Route path="*" element={<ErrorBoundary />} />
            </Routes>
        </Router>
    );
}

export default App;