export const getCityName = (city, language) => {
    if(!city)
        return "";

    switch(language){
        case 'nl':
            return city.dutchName;
        case 'fr':
            return city.frenchName;
        default:
            return city.englishName;
    }
}