import React, { useState, useEffect } from 'react';
import { postponeLegalPersonInformationValidation } from '../http/HttpClientHelper';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LandingPageLegalPersonModal = ({ showModal, handleClose, validationDataToModal, naturalPersonId }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [remainingPostponements, setRemainingPostponements] = useState(0);

    const handleIgnore = async () => {
        await postponeLegalPersonInformationValidation(naturalPersonId);
        handleClose()
    }

    useEffect(() => {
        const remaining = validationDataToModal.maxPostponements - validationDataToModal.currentPostponements;
        setRemainingPostponements(remaining);

        if (remaining === 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [validationDataToModal]);

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title>{t('confirmation.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{t('confirmation.title_company')}</h4>
                <p>{t('confirmation.descriptionCompanies')}</p>
                {validationDataToModal?.companiesToConfirm?.map(company => (
                    <Button key={company.legalPersonId} variant="primary">
                        <Link to={`/eloket/company/basicdata/contact/${company.legalPersonId}`}>{company.name}</Link>
                    </Button>
                ))}
                <h4 className='mt-4'>{t('confirmation.title_office')}</h4>
                <p>{t('confirmation.descriptionOffices')}</p>
                {validationDataToModal?.officesToConfirm?.map(company => (
                    <Button key={company.legalPersonId} variant="primary">
                        <Link to={`/eloket/company/basicdata/contact/${company.legalPersonId}`}>{company.name}</Link>
                    </Button>
                ))}

                <p className='mt-4'>
                    {t('confirmation.countDown', { remaining: remainingPostponements })}
                </p>

                {showAlert && (
                    <div className='alert alert-danger mt-4 mb-0'>
                        <span>{t('confirmation.alertMaxPostponements')}</span>
                    </div>
                )}
            </Modal.Body>
            {validationDataToModal.canIgnorePopup && (
                <Modal.Footer>
                    <Button variant="primary" onClick={handleIgnore}>
                        {t('confirmation.cancel')}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default LandingPageLegalPersonModal