export default {
    nl: {
        title: "MAAK UW KEUZE",
        environment_live_title: "eStox LIVE",
        environment_live_description: "U gaat naar de LIVE omgeving die betalend is. Opgelet! Hierin wordt niet getest. Elke verrichting is reëel en dus betalend. Net als in een papieren aandelenregister mogen/kunnen de verrichtingen bovendien niet verwijderd worden.",
        environment_demo_title: "eStox DEMO",
        environment_demo_description: "U gaat naar de DEMO omgeving die gratis is. U kan in deze omgeving de applicatie uitproberen.",
        title_environment: "TOEGANG TOT DE ESTOX TOEPASSING ({env})",
        pricing_live: "Voor een creatie van een vennootschap in e-Stox betaalt u 25 euro exclusief BTW; voor elke inschrijving in een register per betrokken effectenhouder 5 euro exclusief BTW. Alle verrichtingen die niet leiden tot een inschrijving in het effectenregister zijn net als de consultaties gratis. Daarnaast is er ook een bovengrens van 100 euro exclusief btw per transactie in eStox.",
        pricing_demo: "Dit is een demo omgeving. De verrichtingen zijn niet betalend en hebben geen invloed op het reële effectenregister.",
        choice: "Kies uit onderstaande lijst in naam van welke entiteit u activiteiten uitvoert. Indien uw keuze niet in de lijst voorkomt, neem dan contact op met de hoofdcontactpersoon van het kantoor.",
        questions: "Voor bijkomende vragen kan u contact opnemen met <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
        legal_warning: "Een dossier consulteren of behandelen waarvoor geen mandaat bestaat, kan aanleiding geven tot schade en juridische gevolgen. Hier dient men dus voorzichtig mee om te gaan, rekening houdend met de deontologische voorschriften. <span class='underline'>Alle raadplegingen en wijzigingen worden geregistreerd.</span>",
        pracitcial_warning: "<b>Let op!</b> De applicatie is enkel beschikbaar in de browsers Chrome, Edge en Firefox. Aanvaard ook steeds het verzoek om pop-ups toe te staan.",
        accept: "Ik heb kennis genomen van bovenstaande verplichting.",
        selectTitle: "Maak een keuze",
        ExternalProviderShareRes:{
            InvalidExternalProviderCallbackUrl:"Invalid callback url",
            EstoxInformation:"Voor een creatie van een vennootschap in e-Stox betaalt u 25 euro exclusief BTW; voor elke inschrijving in een register per betrokken effectenhouder 5 euro exclusief BTW. Alle verrichtingen die niet leiden tot een inschrijving in het effectenregister zijn net als de consultaties gratis. Daarnaast is er ook een bovengrens van 100 euro exclusief btw per transactie in eStox.",
            EstoxConnectionIntro:'Kies uit onderstaande lijst in naam van welke entiteit u activiteiten uitvoert. Indien uw keuze niet in de lijst voorkomt, neem dan contact op met <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
            EstoxConnectionNotice:'Hou er rekening mee dat de inactieve ondernemingsnummers gearchiveerd worden, maar niet geschrapt.Indien u vragen heeft, neem dan contact op met <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
            EstoxConnectionOutro:"<strong>Een dossier consulteren of behandelen waarvoor geen mandaat bestaat, kan aanleiding geven tot schade en juridische gevolgen. Hier dient men dus voorzichtig mee om te gaan, rekening houdend met de deontologische voorschriften. <u>Alle raadplegingen en wijzigingen worden geregistreerd. </u></strong>",
            EstoxConnectionBrowserWarning:"<br /><b>Let op!</b> De applicatie is enkel beschikbaar in de browsers Chrome, Edge en Firefox. Aanvaard ook steeds het verzoek om pop-ups toe te staan.",
            ShareAgreement:"Ik geef toestemming dat mijn gegevens van mijn ITAA-account gebruikt mogen worden voor het aanmelden op een externe applicatie.",
            BtnOk:"Bevestigen",
            DropdownDefault:"Maak uw keuze",
            Title:"Toegang tot eStox",
            Redirect:"Even geduld, u wordt doorverwezen",
            Error:"Er liep iets fout bij het indienen van het formulier",
            NoEntity:"Er zijn geen organisaties beschikbaar",
        }
    },
    fr: {
        title: "FAITES VOTRE CHOIX",
        environment_live_title: "eStox LIVE",
        environment_live_description: "Vous allez dans l’environnement LIVE qui est payant. Attention ! Il ne s’agit pas ici d’essais. Chaque inscription est réelle et donc payante. Comme pour une version papier, les inscriptions ne peuvent/savent pas être supprimées.",
        environment_demo_title: "eStox DÉMO",
        environment_demo_description: "Vous allez dans l’environnement DÉMO qui est gratuit et où vous pouvez faire des essais.",
        title_environment: "ACCÈS À L’OUTIL ESTOX ({env})",
        pricing_live: "La création d'une société dans eStox coûte 25 euros hors TVA et chaque inscription dans un registre coûte 5 euros hors TVA par détenteur de titre impliqué. Toutes les opérations qui ne donnent pas lieu à une inscription au registre et les consultations sont gratuites. En outre, il y a un plafond de 100 euro hors TVA par transaction dans eStox.",
        pricing_demo: "Ceci est un environnement de démonstration. Les transactions ne sont pas payantes et n'ont pas d'effet sur le registre des titres réel.",
        choice: "Choisissez dans la liste ci-dessous au nom de quelle entité vous exercez vos activités. Si votre choix ne se trouve pas dans la liste, prenez contact avec la personne de contact principal du cabinet.",
        questions: "Si vous avez des questions supplémentaires, prenez contact avec <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
        legal_warning: "Consulter ou traiter un dossier pour lequel il n’existe pas de mandat peut conduire à des dommages et des poursuites juridiques. Merci d’être prudent et d’intervenir en respectant la déontologie. <span class='underline'>Toutes les consultations et modifications sont enregistrées.</span>",
        pracitcial_warning: "<b>Attention!</b> L'application est uniquement disponible dans les navigateurs Chrome, Edge et Firefox. Accepter toujours la demande pour autoriser les pop-ups.",
        accept: "J’ai pris note de l’obligation susmentionnée.",
        selectTitle: "Faire un choix",
        ExternalProviderShareRes:{
            InvalidExternalProviderCallbackUrl:"",
            EstoxInformation:"La création d'une société dans eStox coûte 25 euros hors TVA et chaque inscription dans un registre coûte 5 euros hors TVA par détenteur de titre impliqué. Toutes les opérations qui ne donnent pas lieu à une inscription au registre et les consultations sont gratuites. En outre, il y a un plafond de 100 euro hors TVA par transaction dans eStox.",
            EstoxConnectionIntro:'Choisissez dans la liste ci-dessous au nom de quelle entité vous exercez vos activités. Si votre choix ne se trouve pas dans la liste, prenez contact avec <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
            EstoxConnectionNotice: 'Tenez compte du fait que les numéros d\'entreprises inactifs sont archivés, mais pas supprimés. Si vous avez des questions, prenez contact avec <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
            ShareAgreement:"J'accepte que mes coordonnées de mon compte ITAA puissent être utilisées pour me connecter à une application externe.",
            BtnOk:"Confirmer",
            DropdownDefault:"Faites votre choix",
            Title:"Accès à eStox",
            EstoxConnectionOutro: "<strong>Consulter ou traiter un dossier pour lequel il n’existe pas de mandat peut conduire à des dommages et des poursuites juridiques. Merci d’être prudent et d’intervenir en respectant la déontologie. <u>Toutes les consultations et modifications sont enregistrées.</u></strong>",
            EstoxConnectionBrowserWarning:"<br /><b>Attention!</b> L'application est uniquement disponible dans les navigateurs Chrome, Edge et Firefox. Accepter toujours la demande pour autoriser les pop-ups.",
            Redirect:"Veuillez patienter, vous êtes en cours de redirection.",
            Error : "Une erreur s'est produite lors de la soumission du formulaire",
            NoEntity : "Aucune organisation n'est disponible"
        }
    },
};