import LegalPersonInfoHeader from "../../LegalPersonInfoHeader/LegalPersonInfoHeader";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getUserCompany, getCompaniesOwnClientele } from "./http/HttpClientHelper";
import { getOffice } from "../../Company/BasicData/http/HttpClientHelper";
import { getMainContact } from '../../Office/MainContact/http/HttpClientHelper';
import { LegalPersonTypeEnum } from "../../../../Enums/LegalPersonTypeEnum";
import { getCoAdmins } from "../../Office/CoAdministrator/http/HttpClientHelper";
import { EntityType } from "../../../../Enums/EntityType";

const ItaaDataDetails = ({ userDetail }) => {
  const [userCompany, setUserCompany] = useState({});
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { legalPersonId } = useParams();
  const localStorageCompanies = localStorage.getItem('companies');
  const userCompanies = JSON.parse(localStorageCompanies);
  let foundCompany = userCompanies.find(company => company.legalPersonId === legalPersonId);
  let companyItaaNumber = foundCompany.itaaNumber;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const company = await getUserCompany(userDetail.id, legalPersonId);
      const clienteleInfo = await getCompaniesOwnClientele(company.legalPersonId);
      const office = await getOffice(company.legalPersonId);
      const mainContact = await getMainContact(company.legalPersonId);
      const coAdmins = await getCoAdmins(company.legalPersonId);
      company.isOffice = office.companyType?.name === "Office";
      company.isManagementCompany = clienteleInfo.isManagementCompany;
      setUserCompany({
        ...company,
        office,
        mainContact: mainContact?.mainContact?.fullName || "-",
        coAdmins: coAdmins?.coAdministrators || [],
      });
      setLoading(false);
    };

    fetchData();
  }, [legalPersonId, userDetail]);

  const navigationData = [
    {
      url: "/eloket/company/itaadata/general/",
      title: t("sidebar.general"),
      text: t("navCompaniesDetail.generalDataDescription"),
      newTab: false,
      managementCompanyData: false,
    },
    {
      
      url: "/eloket/company/itaadata/maincontact/",
      title: t("navCompaniesDetail.mainContact"),
      text: t("navCompaniesDetail.mainContactDescription"),
      newTab: false,
      managementCompanyData: true,
    },
    {
      url: "/eloket/company/itaadata/coadministrator/",
      title: t("navCompaniesDetail.coadministrator"),
      text: t("navCompaniesDetail.coadministratorDescription"),
      newTab: false,
      managementCompanyData: true,
    },
    {
     
      url:userCompany.office?.entityType?.id === EntityType.EPP  ? `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${userDetail?.itaaNumber}&ExternalLinkType=0`
                                                            : `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${companyItaaNumber}&ExternalLinkType=1`,
      title: t("sidebar.publicprofile"),
      text: t("itaadata.textCompany"),
      newTab: true,
      managementCompanyData: false,
    },
    {
      url: "/eloket/company/itaadata/directorAndPartner/",
      title: t("sidebar.directorAndPartner"),
      text: t("directorAndPartner.text"),
      newTab: false,
      managementCompanyData: false,
    },
    {
      url: "/eloket/company/itaadata/network/",
      title: t("sidebar.network"),
      text: t("network.subtitle"),
      newTab: false,
      managementCompanyData: false,
    },
  ];


  return (
    <div className="container" id="main">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}

      <div>
        <LegalPersonInfoHeader
          legalPersonId={legalPersonId}
          userDetail={userDetail}
          legalPersonType={LegalPersonTypeEnum.Company}
        />
        <Row className="row-m-1">
          {navigationData.map((item, index) => {
            const shouldShowItem =
              !item.managementCompanyData ||
              (!userCompany?.isOffice && item.managementCompanyData) ||
              !userCompany?.isOffice;

            if (shouldShowItem) {
              return (
                <Col key={index} md={4}>
                  <Link
                    to={item.url} target={item.newTab ? "_blank" : "_self"}
                  >
                    <Card lang={i18n.language} className="h-100 custom-card">
                      <Card.Body>
                        <Card.Title className="card-title">{item.title}</Card.Title>
                        <Card.Text>{item.text}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            }

            return null;
          })}
        </Row>

      </div>
    </div>
  );
};

export default ItaaDataDetails;
