import { jwtDecode } from 'jwt-decode';

export const checkLoginWithEIdItsMe = () => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
        const decodedToken = jwtDecode(token);
        const { sub } = decodedToken;
        if (sub.includes('BOSA')) {
            return true;
        }
    }
    return false;
}