import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { patchNaturalPersonInformationValidation } from '../http/HttpClientHelper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const LandingPageModal = ({ showModal, handleClose, validationDataToModal, naturalPersonId, canIgnore}) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [remainingPostponements, setRemainingPostponements] = useState(0);

    const handleIgnore = async () => {
        await patchNaturalPersonInformationValidation(naturalPersonId, {
            hasIgnored: true,
            hasConfirmed: false,
        })
        handleClose();
    };

    useEffect(() => {
        const remaining = validationDataToModal.maxPostponements - validationDataToModal.currentPostponements;
        setRemainingPostponements(remaining);

        if (remaining === 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [validationDataToModal]);

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title>{t('confirmation.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('confirmation.descriptionPersonal')}
                </p>
                {
                    canIgnore === false  && 
                        <div>
                            <p>
                                {t('confirmation.descriptionPersonalCanIgnore')}
                            </p>
                            <p>
                                {t('confirmation.countDown', { remaining: remainingPostponements })}
                            </p>
                        </div>
                }


                {showAlert && (
                    <div className='alert alert-danger mt-4 mb-0'>
                        <span>{t('confirmation.alertMaxPostponements')}</span>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {canIgnore === false   && (
                    <Button variant="primary" onClick={handleIgnore}>
                        {t('confirmation.cancel')}
                    </Button>
                )}
                <Button variant="primary">
                    <Link to="/eloket/data">{t('confirmation.confirm')}</Link>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LandingPageModal;
